<template>
  <header
    class="flex flex-wrap md:flex-nowrap justify-between gap-x-8 gap-y-3 mb-8"
  >
    <input
      v-model="benficiaryNameQueryModel"
      class="w-full h-10 border border-greyscale-7 text-sm text-primary rounded-[5px] focus:outline-primary placeholder:text-sm px-4 py-1 placeholder:text-primary-primary bg-gray-50"
      placeholder="Search beneficiaries by name"
      type="text"
    />
    <div
      class="w-auto md:min-w-max flex flex-wrap md:justify-end gap-x-3 gap-y-3"
    >
      <!-- <filter-menu :options="[]" :handle-change="() => {}" /> -->

      <app-button
        v-if="hasPermission(LyncPermissions.counterparties_write)"
        variant="primary"
        size="md"
        @click="openAddBeneficiaryModal"
      >
        <span class="w-[13px] h-[14px] inline-block"><plus-icon /></span>
        <span class="hidden md:block">New beneficiary</span>
        <span class="md:hidden">Create</span>
      </app-button>
    </div>
  </header>
  <app-table
    v-if="!isError"
    :data="beneficiaries?.items || []"
    :columns="columns"
    :loading="isLoading"
    :pagination="{
      currentPage,
      perPage,
      totalItems: beneficiaries?.total_items || 0,
    }"
    @mobile-row-click="handleMobileRowClicked"
    @change="handleTableChange"
  >
    <template #column-name="props">
      <div
        v-if="props.row.name || props.row.friendly_name"
        class="flex gap-x-2 capitalize"
      >
        <user-icon />
        {{
          props.row.name?.toLowerCase() ||
          props.row.friendly_name?.toLowerCase()
        }}
      </div>
    </template>

    <template #column-type="props">
      <span
        v-if="props.row.details"
        class="text-center bg-greyscale-1 rounded px-2 py-1"
      >
        {{ snakeCaseToWords(props.row.details.type) }}
      </span>
    </template>

    <template #column-account="props">
      <div v-if="props.row.details" class="flex gap-x-2 items-center">
        <institution-icon />
        <div class="flex flex-col">
          <span>
            <strong>{{
              props.row.details.details?.accountNumber ||
              props.row.details.details?.account_number
            }}</strong>
            -
            {{
              props.row.details.details?.bankName ||
              props.row.details.details?.bank_name ||
              "N/A"
            }}
          </span>
          <span>
            {{
              props.row.details.details?.accountName ||
              props.row.details.details?.account_name
            }}
          </span>
        </div>
      </div>
    </template>

    <template #column-action="props">
      <router-link
        class="underline underline-offset-2"
        :to="`/beneficiaries/${props.row.id}?type=banking`"
      >
        View
      </router-link>
    </template>
    <template #empty-state>
      <div
        class="w-full max-w-[300px] mx-auto flex flex-col gap-y-3 justify-center items-center"
      >
        <empty-data />
        <h4 class="font-bold text-base lg:text-lg">
          Add your first beneficiary
        </h4>
        <p class="text-base text-center text-text-primary">
          No beneficiaries have been added to this account yet.
        </p>
        <app-button
          v-if="hasPermission(LyncPermissions.counterparties_write)"
          variant="primary"
          size="md"
          @click="openAddBeneficiaryModal"
          >Add beneficiary</app-button
        >
      </div>
    </template>
  </app-table>
  <error-component v-else-if="isError" message="Error fetching beneficiaries" />
  <app-modal
    :is-open="isAddModalOpen"
    :handle-close="closeAddBeneficiaryModal"
    size="lg"
  >
    <create-banking-beneficiary :close-modal="closeAddBeneficiaryModal" />
  </app-modal>
</template>

<script setup lang="ts">
import {
  TableChangeParams,
  TableColumn,
} from "@/components/shared/table/table.props";
import { useGetBeneficiaries } from "@/data-access/beneficiary";
import { BeneficiaryResponse } from "@/types";
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { debounce } from "lodash";
import { snakeCaseToWords } from "@/helpers";
import { LyncPermissions } from "@/security/permissions";
import { usePermission } from "@/composables/use-permission";

const isAddModalOpen = ref(false);
const benficiaryNameQueryModel = ref("");
const benficiaryNameQuery = ref("");

const currentPage = ref(1);
const perPage = ref(10);
const route = useRoute();

const { hasPermission } = usePermission();

const newBeneficiaryQuery = route.query["add-new"];
const router = useRouter();
const {
  data: beneficiaries,
  isLoading,
  isError,
} = useGetBeneficiaries({
  page: currentPage,
  limit: perPage,
  filters: {
    name: benficiaryNameQuery,
    type: ref("FIAT"),
  },
});

const openAddBeneficiaryModal = () => {
  isAddModalOpen.value = true;
};

const closeAddBeneficiaryModal = () => {
  isAddModalOpen.value = false;
};

watch(
  benficiaryNameQueryModel,
  debounce((val) => {
    benficiaryNameQuery.value = val;
  }, 1000),
);

const columns: TableColumn<
  BeneficiaryResponse & {
    action: string;
  }
>[] = [
  {
    label: "Name",
    selector: (row) => row.name || row.friendly_name,
    dataIndex: "name",
    showOnMobile: false,
  },
  {
    label: "Type",
    selector: (row) => row.type,
    dataIndex: "type",
    showOnMobile: false,
  },
  {
    label: "Account Number / Address",
    selector: () => {},
    dataIndex: "account",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: false,
  },
  {
    label: "",
    selector: (row) => row.name || row.friendly_name,
    dataIndex: "name",
    showOnMobile: true,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "type",
    showOnMobile: true,
  },
];

const handleMobileRowClicked = (row: BeneficiaryResponse) => {
  router.push(`/beneficiaries/${row.id}`);
};

const handleTableChange = (params: TableChangeParams) => {
  (perPage.value = params.perPage), (currentPage.value = params.currentPage);
};

onMounted(() => {
  if (newBeneficiaryQuery) {
    setTimeout(() => {
      openAddBeneficiaryModal();
    }, 500);
  }
});
</script>
