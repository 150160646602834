import apiClient, { useRequestOptions } from "@/helpers/apiClient";
import {
  QueryKeys,
  RequestOptions,
  FxRateResponse,
  PaginatedResponse,
} from "@/types";
import { useQuery } from "@tanstack/vue-query";

export const useGetFxRatesHistory = (
  options: RequestOptions,
  enabled: boolean = true,
) => {
  return useQuery<PaginatedResponse<FxRateResponse>>({
    queryKey: [
      QueryKeys.FX_RATES,
      options.page,
      options.limit,
      ...Object.values(options.filters || {}),
    ],
    queryFn: () =>
      apiClient
        .get(`v1/rates/history?${useRequestOptions(options)}`)
        .then((res) => res.data.data),
    enabled,
  });
};

export const useGetCurrentFxRates = () => {
  return useQuery<FxRateResponse[]>({
    queryKey: [QueryKeys.CURRENT_FX_RATES],
    queryFn: () =>
      apiClient.get(`v1/rates/current`).then((res) => res.data.data),
  });
};
