<template>
  <label class="w-full">
    <div class="w-full flex flex-col gap-y-[9px]">
      <span class="text-sm font-medium text-primary w-fit min-w-fit">{{
        label
      }}</span>
      <div class="flex gap-x-1 items-end">
        <div class="min-w-[110px] w-[110px]">
          <select-input
            :data="countriesPhoneCode"
            placeholder="+XXX"
            :default-value="phoneCode"
            :set-custom-display-value="setDisplayValue"
            @select="handleSelectedPhoneCode"
          />
        </div>
        <input
          v-model="phoneNumber"
          class="w-full h-10 border border-[#E2E2E2] px-4 py-2 rounded-[5px] focus:outline-primary text-sm text-primary placeholder:text-sm"
          type="number"
          placeholder="Enter your phone number"
          required
        />
      </div>
    </div>
    <span class="text-failed text-sm flex items-center gap-x-[2px] mt-1"
      ><caution-icon v-if="error" class="fill-failed min-w-[24px]" />
      {{ error && error }}</span
    >
  </label>
</template>

<script lang="ts" setup>
import { watch, ref, computed } from "vue";
import { countriesList } from "@/helpers/countries";
import { SelectInputData } from "@/components/shared/type";

interface Props {
  label: string;
  errorMessage?: string;
}

const props = defineProps<Props>();
const error = ref(props.errorMessage);

const countriesPhoneCode = computed(() => {
  return countriesList.map((it) => ({
    label: `${it.emoji} ${it.dial_code} ${it.name}`,
    value: it.dial_code,
  }));
});

const phoneCode = ref("");
const phoneNumber = ref("");

const handleSelectedPhoneCode = (code: SelectInputData) => {
  phoneCode.value = code.value;
};

const setDisplayValue = (data: SelectInputData) => {
  const [flag, code] = data.label.split(" ");

  return `${flag} ${code}`;
};

const emit = defineEmits<{
  (e: "change", value: string): void;
}>();

watch([phoneCode, phoneNumber], () => {
  if (phoneCode.value && !phoneNumber.value) {
    error.value = "Phone number is required";
  }

  if (phoneNumber.value && !phoneCode.value) {
    error.value = "Phone code is required";
  }

  if (phoneCode.value && phoneNumber.value) {
    error.value = "";
    emit("change", `${phoneCode.value}${parseInt(phoneNumber.value)}`);
  }
});
</script>
