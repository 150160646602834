<template>
  <Combobox v-model="selectedBeneficiary">
    <div class="relative">
      <span
        class="text-sm text-primary font-medium w-fit min-w-fit inline-block mb-[9px]"
        >Beneficiary</span
      >
      <div class="relative w-full overflow-hidden text-sm text-primary">
        <ComboboxInput
          class="w-full border border-[#E2E2E2] py-2 pl-4 pr-10 text-sm text-primary-primary rounded-[5px] focus:ring-0 focus:outline-primary placeholder:text-sm"
          :display-value="(it: any) => getDisplayValue(it)"
          placeholder="Enter beneficiary name"
          @change="query = $event.target.value"
        />
        <ComboboxButton
          class="absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <arrow-down-icon />
        </ComboboxButton>
      </div>
      <div
        v-if="isError"
        class="relative cursor-default select-none px-4 py-2 text-sm text-text-primary"
      >
        Error fetching beneficiaries - Contact support
      </div>
      <ComboboxOptions
        v-if="beneficiaries"
        class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm"
      >
        <div
          v-if="beneficiaryList.length === 0 && query !== ''"
          class="relative cursor-default select-none px-4 py-2 text-text-primary"
        >
          Nothing found.
        </div>
        <div
          v-else-if="!beneficiaryList.length"
          class="relative cursor-default select-none px-4 py-2 text-text-primary"
        >
          You have no {{ currency || "" }} beneficiaries yet
        </div>
        <ComboboxOption
          v-for="beneficiary in beneficiaryList"
          :key="beneficiary.id"
          v-slot="{ selected, active }"
          as="template"
          :value="beneficiary"
        >
          <li
            class="relative cursor-default select-none py-2 pl-3 pr-3 md:pl-10 md:pr-4"
            :class="{
              'bg-greyscale-2 text-primary': active,
              'text-text-primary': !active,
            }"
          >
            <span
              class="block truncate"
              :class="{
                'font-medium': selected,
                'font-normal': !selected,
              }"
            >
              {{ beneficiary.name || beneficiary.friendly_name }}
              <span v-if="beneficiary.type === 'FIAT'">
                ({{
                  beneficiary.details.type === "FED_WIRE"
                    ? "WIRE"
                    : beneficiary.details.type
                }}
                Account -
                {{ beneficiary.details.details.account_number }})
              </span>
              <span v-else-if="beneficiary.type === 'CRYPTO'">
                ({{ beneficiary.details.blockchain }} -
                {{ beneficiary.details.address }})
              </span>
            </span>
            <span
              v-if="selected"
              class="absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <check-icon aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script lang="ts" setup>
import { useGetBeneficiaries } from "@/data-access/beneficiary";
import { BeneficiaryResponse, ServiceType } from "@/types";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import { watch, ref, computed } from "vue";

const props = defineProps<{
  currency: string;
  network?: string;
  defaultBeneficiary?: BeneficiaryResponse;
  type: ServiceType;
}>();

const emit = defineEmits<{
  (e: "select", value: BeneficiaryResponse): void;
}>();

const selectedCurrency = ref(props.currency);
const selectedNetwork = ref(props.network);
const query = ref("");

const beneficiaryType = computed(() => {
  if (props.type === "banking") {
    return "FIAT";
  } else if (props.type === "crypto") {
    return "CRYPTO";
  }
  return "";
});

const { data: beneficiaries, isError } = useGetBeneficiaries({
  page: ref(0),
  limit: ref(1000),
  filters: {
    name: query,
    currency: ref(props.currency),
    type: ref(beneficiaryType.value),
  },
});

const beneficiaryList = computed(() => {
  if (props.network) {
    return (
      beneficiaries?.value?.items.filter(
        (it) => it.details?.blockchain === props.network,
      ) || []
    );
  }
  return beneficiaries?.value?.items || [];
});

const selectedBeneficiary = ref<BeneficiaryResponse | null>(
  props.defaultBeneficiary || null,
);

watch(selectedBeneficiary, (it) => {
  if (it) {
    emit("select", it);
  }
});

watch(
  () => props.currency,
  (it) => {
    selectedCurrency.value = it;
    if (selectedBeneficiary.value?.currency !== it) {
      selectedBeneficiary.value = null;
    }
  },
);

watch(
  () => props.network,
  (it) => {
    selectedNetwork.value = it;
    if (
      selectedBeneficiary.value?.details &&
      selectedBeneficiary.value?.details?.blockchain !== it
    ) {
      selectedBeneficiary.value = null;
    }
  },
);

const getDisplayValue = (it: BeneficiaryResponse) => {
  if (props.type === "banking" && it && it.type === "FIAT" && it.details) {
    return `${it.name || it.friendly_name} (${
      it.details.type === "FED_WIRE" ? "WIRE" : it.details.type
    } - ${it.details.details.account_number})`;
  } else if (it && it.type === "FIAT" && it.details) {
    return `${it.name || it.friendly_name} (${
      it.details.type === "FED_WIRE" ? "WIRE" : it.details.type
    } - ${it.details?.details?.account_number})`;
  } else if (it && it.type === "CRYPTO" && it.details) {
    return `${it.name} (${it.details.blockchain} - ${it.details.address})`;
  }
  return it?.name || it?.friendly_name || "";
};
</script>
