<template>
  <div class="relative bg-white overflow-hidden w-full h-full overflow-y-auto">
    <div
      class="w-full bg-greyscale-1 h-28 px-5 py-5 flex justify-center items-center"
    >
      <div
        v-if="transaction.type === 'DEPOSIT'"
        class="font-[800] text-4xl text-primary"
      >
        +
        {{ transaction.currency }}
        {{
          formatAmountToMajor(
            transaction.destination_amount,
            transaction.currency,
          )
        }}
      </div>
      <div v-else class="font-[800] text-4xl text-primary">
        -
        {{ transaction.currency }}
        {{
          formatAmountToMajor(transaction.sending_amount, transaction.currency)
        }}
      </div>
    </div>
    <div class="w-full flex flex-col justify-between py-5">
      <div class="w-full px-5">
        <ul class="mb-8 flex flex-col gap-y-3">
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Date</div>
            <div class="text-primary text-right">
              {{ formatDateTime(transaction.created_date) }}
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Reference</div>
            <div class="text-primary text-right">
              {{ transaction.reference }}
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Type</div>
            <div v-if="isTransactionOtc" class="flex gap-x-1 justify-end">
              <debit-icon />
              <span class="capitalize text-[#B96B6B]">Otc</span>
            </div>
            <div v-else class="flex justify-end gap-x-1">
              <credit-icon v-if="transaction.type === 'DEPOSIT'" />
              <debit-icon v-else-if="transaction.type === 'TRANSFER'" />
              <exchange-icon v-else-if="transaction.type === 'EXCHANGE'" />
              <span
                class="capitalize"
                :class="
                  transaction.type === 'DEPOSIT'
                    ? 'text-[#297FB0]'
                    : transaction.type === 'TRANSFER'
                      ? 'text-[#B96B6B]'
                      : 'text-processing/70'
                "
                >{{ transaction.type?.toLowerCase() }}</span
              >
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Status</div>
            <div class="text-primary text-right">
              <transaction-status :status="transaction.state" />
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Description</div>
            <div class="text-primary text-right">
              {{ transaction.description }}
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Amount Sent</div>
            <div class="text-primary text-right">
              {{ transaction.currency }}
              {{
                formatAmountToMajor(
                  transaction.sending_amount,
                  transaction.currency,
                )
              }}
            </div>
          </li>

          <!-- <li
            v-for="it in transaction?.fee_breakdown || []"
            :key="String(it)"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">{{ it.description }}</div>
            <div class="text-primary text-right">
              {{ formatAmountToMajor(it.amount, it.currency) }}
              {{ it.currency }}
            </div>
          </li> -->

          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Fees</div>
            <div class="text-primary text-right">
              {{ transaction.currency }}
              {{
                formatAmountToMajor(
                  transaction.total_fee_amount,
                  transaction.currency,
                )
              }}
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Amount Received</div>
            <div class="text-primary text-right">
              {{ transaction.destination_currency }}
              {{
                formatAmountToMajor(
                  transaction.destination_amount,
                  transaction.destination_currency,
                )
              }}
            </div>
          </li>
        </ul>
        <h3
          v-if="
            transaction.type === 'DEPOSIT' && transaction.source.counterparty
          "
          class="text-sm font-medium mb-3"
        >
          SENDER
        </h3>

        <ul
          v-if="
            transaction.type === 'DEPOSIT' && transaction.source.counterparty
          "
          class="w-full flex flex-col gap-y-5"
        >
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Type</div>
            <div class="text-primary text-right">
              {{ convertToWords(transaction.source.counterparty.type) }}
            </div>
          </li>

          <div
            v-for="it in Object.keys(
              transaction.source.counterparty.details,
            ).filter((it) => !['bank_code', 'nip_code'].includes(it))"
            :key="it"
            class="w-full flex flex-col gap-y-5"
          >
            <template
              v-if="
                typeof transaction.source.counterparty.details[it] === 'object'
              "
            >
              <li
                v-for="item in Object.keys(
                  transaction.source.counterparty.details[it],
                )"
                :key="item"
                class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
              >
                <div class="text-text-secondary">
                  {{ convertToWords(it) }} {{ convertToWords(item) }}
                </div>
                <div class="text-primary text-right">
                  {{ transaction.source.counterparty.details[it][item] }}
                </div>
              </li>
            </template>

            <li
              v-else
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">{{ convertToWords(it) }}</div>
              <div class="text-primary text-right">
                {{ transaction.source.counterparty.details[it] }}
              </div>
            </li>
          </div>
        </ul>

        <h3
          v-if="
            transaction.type !== 'DEPOSIT' &&
            transaction.destination.counterparty
          "
          class="text-sm font-medium mb-3 mt-8"
        >
          BENEFICIARY
          <router-link
            :to="`/beneficiaries/${transaction.destination.id}`"
            class="text-sm text-text-primary/70 underline"
            >(View)</router-link
          >
        </h3>

        <ul
          v-if="
            transaction.type !== 'DEPOSIT' &&
            transaction.destination.counterparty
          "
          class="w-full flex flex-col gap-y-5"
        >
          <li
            v-if="transaction.destination.name"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Name</div>
            <div class="text-primary text-right">
              {{ convertToWords(transaction.destination.name) }}
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Type</div>
            <div class="text-primary text-right">
              {{ convertToWords(transaction.destination.counterparty.type) }}
            </div>
          </li>

          <li
            v-if="txnCounterparty.bank_name"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Bank Name</div>
            <div class="text-primary text-right">
              {{ txnCounterparty.bank_name }}
            </div>
          </li>
          <li
            v-if="txnCounterparty.account_number"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Account number</div>
            <div class="text-primary text-right">
              {{ txnCounterparty.account_number }}
            </div>
          </li>
          <li
            v-if="txnCounterparty.account_name"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Account name</div>
            <div class="text-primary text-right">
              {{ txnCounterparty.account_name }}
            </div>
          </li>
          <li
            v-if="txnCounterparty.swift_bic"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Swift/Bic</div>
            <div class="text-primary text-right">
              {{ txnCounterparty.swift_bic }}
            </div>
          </li>
          <li
            v-if="txnCounterparty.bank_address?.country"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Bank Country</div>
            <div class="text-primary text-right">
              {{ countryOf(txnCounterparty.bank_address.country).name }}
            </div>
          </li>

          <li
            v-if="txnCounterparty.intermediary_bank"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Intermediary Bank Name</div>
            <div class="text-primary text-right">
              {{ txnCounterparty.intermediary_bank.name }}
            </div>
          </li>

          <li
            v-if="txnCounterparty.intermediary_bank"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Intermediary Bank Swift/Bic</div>
            <div class="text-primary text-right">
              {{ txnCounterparty.intermediary_bank.swift_bic }}
            </div>
          </li>

          <li
            v-if="txnCounterparty.intermediary_bank?.address?.country"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Intermediary Bank Country</div>
            <div class="text-primary text-right">
              {{
                countryOf(txnCounterparty.intermediary_bank.address.country)
                  .name
              }}
            </div>
          </li>
        </ul>
      </div>

      <div
        v-if="
          transaction.state === 'PENDING_APPROVAL' &&
          hasPermission(LyncPermissions.transactions_approve)
        "
        class="w-full px-5 mt-8"
      >
        <div class="flex justify-between gap-x-2">
          <app-button
            size="lg"
            variant="outlined"
            :loading="rejecting"
            :disabled="rejecting"
            @click="rejectRequest"
          >
            Reject payment
          </app-button>
          <app-button
            size="lg"
            variant="primary"
            :loading="approving"
            :disabled="approving"
            @click="approveRequest"
          >
            Approve payment
          </app-button>
        </div>
      </div>

      <div class="flex mt-8 justify-between items-center gap-x-4 px-5">
        <router-link to="/transactions" class="w-full">
          <app-button variant="outlined" size="lg"
            >Go to transactions</app-button
          >
        </router-link>
        <app-button
          v-if="transaction.state === 'COMPLETED'"
          :disabled="downloading"
          :loading="downloading"
          variant="primary"
          size="lg"
          @click="handleDownloadReceipt"
          >Download receipt</app-button
        >
      </div>
    </div>
    <button
      class="border w-10 h-10 rounded-full flex items-center justify-center absolute right-5 top-5"
      @click="closeModal"
    >
      <close-icon />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { formatDateTime, convertToWords, formatAmountToMajor } from "@/helpers";
import { countryOf } from "@/helpers/countries";
import apiClient, { bankingUrl } from "@/helpers/apiClient";
import { useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { BankingTransactionResponse, QueryKeys } from "@/types";
import { ref, computed } from "vue";
import { usePermission } from "@/composables/use-permission";
import { useRoute } from "vue-router";
import { useAppToast } from "@/composables";
import { LyncPermissions } from "@/security/permissions";
import { useQueryClient } from "@tanstack/vue-query";

const props = defineProps<{
  transaction: BankingTransactionResponse;
  refetch: () => void;
  closeModal: () => void;
}>();

const route = useRoute();
const downloading = ref(false);
const { hasPermission } = usePermission();

const toast = useAppToast();
const queryClient = useQueryClient();

const isTransactionOtc = computed(() => {
  return props.transaction.tags
    ? props.transaction.tags.includes("OTC")
    : false;
});

const txnCounterparty = computed(
  () => props.transaction.destination?.counterparty?.details || {},
);

const handleDownloadReceipt = async () => {
  try {
    downloading.value = true;
    const res = await apiClient.get(
      bankingUrl(`/payments/${props.transaction.id}/receipt`),
      {
        responseType: "blob",
      },
    );
    downloading.value = false;
    const file = window.URL.createObjectURL(res.data);
    const docUrl = document.createElement("a");
    docUrl.href = file;
    docUrl.setAttribute("download", `receipt-${route.params.id}.pdf`);
    document.body.appendChild(docUrl);
    docUrl.click();
    document.body.removeChild(docUrl);
  } catch (err) {
    downloading.value = false;
    toast.error(errorMessage(err), {
      position: "top-right",
    });
  }
};

const { submitting: approving, execute: approveRequest } = useWriteResource(
  `/approval-requests/${props.transaction.id}/approve`,
  "patch",
  {
    successTitle: `Request Approval successful`,
    onSuccess: () => {
      props.refetch();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.APPROVAL_REQUESTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.BANKING_TRANSACTIONS],
      });
      props.closeModal();
    },
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { submitting: rejecting, execute: rejectRequest } = useWriteResource(
  `/approval-requests/${props.transaction.id}/reject`,
  "patch",
  {
    successTitle: `Request Rejection successful`,
    onSuccess: () => {
      props.refetch();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.APPROVAL_REQUESTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.BANKING_TRANSACTIONS],
      });
      props.closeModal();
    },
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);
</script>
